import React from 'react';
import sygnet from '../assets/images/sygnet_color_left.svg';
import icon from '../assets/images/icon_about_bizami_section.svg';
import { SectionHeroStyles } from '../styles/SectionAboutBizamiStyles';


export default function AboutBizami({ data}) {
  
  return (
    
    <section id="readMoreAbout">
      
      <SectionHeroStyles>
        <div className="content">
          <img
            className="sygnet"
            src={sygnet}
            width="159"
            height="354"
            alt=""
          />
          <div className="content-text">
            <span className="h5">{data.subtitleT}</span>
            <h2>
              <span className="title-marked accent">{data.titleT}</span>
            </h2>
            <p>
              <span role="img" aria-label="Icon globe">
                🌐
              </span>{' '}
              {data.descriptionT}
            </p>
          </div>
          <img className="icon" src={icon} width="390" height="390" alt="" />
        </div>
      </SectionHeroStyles>
    </section>
  );
}

